<template>
  <div class="p-3">
    <div v-if="weather" class="mb-2">
      <img
        :src="weather.icon_url"
        :alt="weather.description"
        class="img-fluid rounded"
        style="max-width: 50px; margin: -5px 0"
      />
      {{ weather.temp.toFixed() }}°C
      {{ weather.description }}
    </div>
    <WeatherWarningList class="mb-2" />
  </div>
</template>

<script>
import httpService from "@/services/HttpService";
import WeatherWarningList from "./WeatherWarningList.vue";
import RefreshableMixin from "@/mixins/RefreshableMixin";

export default {
  mixins: [RefreshableMixin],
  components: { WeatherWarningList },
  data() {
    return {
      isLoading: false,
      weather: null,
    };
  },
  mounted() {
    this.isLoading = false;
    this.weather = null;
    this.refreshData();
  },
  methods: {
    refreshData() {
      httpService
        .get(`/api/places/${this.$route.params.id}/weather`, {
          suppressErrorToast: true,
          handleLoading: (isLoading) => (this.isLoading = isLoading),
        })
        .then((response) => {
          this.weather = response.data;
        })
        /* istanbul ignore next */
        .catch(/* istanbul ignore next */ () => /* istanbul ignore next */ {});
    },
  },
};
</script>
